import { SliderWrapper } from '../../SliderWrapper';
import { Card } from '../../Cards';
import { ISubcategoryCardItem } from '../index';
import { Slider } from '@/components/SliderVanilla';

const CardsWithData = ({
  cardData,
  isOdd,
  copyDictionary,
  clickSubcategory,
}: {
  clickSubcategory?: (data?: any) => void;
  cardData: ISubcategoryCardItem[];
  isOdd?: boolean;
  copyDictionary?: Record<string, string>;
}) => {
  return (
    <SliderWrapper leftShift={0} extraWidth={20} className="subcategory-slider">
      <Slider slidesPerView={3.5}>
        {cardData?.map((item, index: number) => (
          <div
            className={`subcategory-card mr-2.5 min-w-[88%] md:mr-9 md:min-w-[31%]`}
            key={item.id ?? index}
            onClick={() => clickSubcategory && clickSubcategory(item)}
          >
            <Card
              data={item}
              copyDictionary={copyDictionary}
              link={item.link}
              cardVariation="articleImage"
              cardType="tiny"
              height="360px"
              position={index + 1}
              isOdd={isOdd}
            />
          </div>
        ))}
      </Slider>
    </SliderWrapper>
  );
};

export default CardsWithData;
