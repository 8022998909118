import { useState, useEffect } from 'react';
import { Card } from '../Cards';
import { Grid, GridRow, GridItem } from '../Grid';
import NextImage from '../NextImage';
import type { ICardItem as ICard } from '../Cards/types';
import { ImagesService } from 'service/images/imagesService';
import CardsWithData from './components/CardsWithData';

export interface ISubcategoryCardCarousel {
  categoryData?: ISubcategoryCardItem;
  cardData?: ISubcategoryCardItem[];
  copyDictionary?: Record<string, string>;
  tracking?: Record<string, string>;
  categoryCardVariation?: 'recipe' | 'object';
  trackingEvents?: {
    clickCategory: () => void;
    clickSubcategory: (data?: any) => void;
  };
  isOdd?: boolean;
}
export interface ISubcategoryCardItem extends ICard {
  promoImage?: {
    desktop?: string;
    mobile?: string;
    mimeType?: string;
  };
  link?: {
    url: string;
    displayName: string;
    openInNewTab: boolean;
  };
}

export const CategorySubcategory = ({
  categoryData,
  cardData,
  copyDictionary,
  categoryCardVariation,
  trackingEvents,
  isOdd = false,
}: ISubcategoryCardCarousel) => {
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const screenSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    screenSize();
    window.addEventListener('load', screenSize);
    window.addEventListener('resize', screenSize);
    return () => window.removeEventListener('resize', screenSize);
  }, []);

  return (
    <Grid>
      <GridRow columns={16} tabletBreakpoint>
        <GridItem colSpan={screenWidth < 1024 ? 12 : 4}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
          jsx-a11y/no-static-element-interactions */}
          <div onClick={trackingEvents?.clickCategory} className="px-2 md:px-0">
            <Card
              data={categoryData}
              copyDictionary={copyDictionary}
              link={categoryData?.link}
              cardVariation={categoryCardVariation || 'object'}
              cardType="mediumSmall"
              height="360px"
              isGrayBg
              showAll
            />
          </div>
        </GridItem>
        <GridItem colSpan={12}>
          {cardData?.length ? (
            <CardsWithData
              clickSubcategory={trackingEvents?.clickSubcategory}
              cardData={cardData}
              copyDictionary={copyDictionary}
              isOdd={isOdd}
            />
          ) : (
            <div className=" w-full h-full relative rounded-2xl overflow-hidden">
              <NextImage
                className="w-full h-full object-cover"
                sources={ImagesService.getPictureSources(categoryData?.promoImage)}
                mimeType={String(categoryData?.promoImage?.mimeType)}
                alt=""
              />
            </div>
          )}
        </GridItem>
      </GridRow>
    </Grid>
  );
};
